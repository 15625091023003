export const GoogleLogo = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M20 12.18C20 11.6533 19.9523 11.1533 19.8706 10.6667H12.1738V13.6733H16.5807C16.3832 14.66 15.8042 15.4933 14.946 16.06V18.06H17.5752C19.1145 16.6667 20 14.6133 20 12.18Z"
        fill="#4285F4"
      ></path>
      <path
        d="M12.1738 20C14.3806 20 16.2265 19.28 17.5751 18.06L14.946 16.06C14.2104 16.54 13.2772 16.8333 12.1738 16.8333C10.0419 16.8333 8.23687 15.4267 7.58979 13.5267H4.87891V15.5867C6.22073 18.2 8.97929 20 12.1738 20Z"
        fill="#34A853"
      ></path>
      <path
        d="M7.58986 13.5267C7.41957 13.0467 7.33103 12.5333 7.33103 12C7.33103 11.4667 7.42638 10.9533 7.58986 10.4733V8.41334H4.87897C4.30118 9.52435 4 10.7533 4 12C4 13.2467 4.30118 14.4757 4.87897 15.5867L7.58986 13.5267Z"
        fill="#FBBC05"
      ></path>
      <path
        d="M12.1738 7.16667C13.3794 7.16667 14.4556 7.57333 15.307 8.36667L17.6364 6.08667C16.2265 4.79333 14.3806 4 12.1738 4C8.97929 4 6.22073 5.8 4.87891 8.41333L7.58979 10.4733C8.23687 8.57333 10.0419 7.16667 12.1738 7.16667Z"
        fill="#EA4335"
      ></path>
    </svg>
  )
}
